import React from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10rem;
    margin: 2rem;
    text-align: center;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
        margin: 1rem;
    }
`
const Number = styled.h1`
    margin-bottom: 0.2rem;
    font-size: 1.75rem;
    font-weight: 600;
    color: ${(props) => props.theme.color.tellowPurple};
`
const Text = styled.p`
    margin-bottom: 0.2rem;
    font-size: 0.875rem;
    font-weight: 400;
    font-style: italic;
    color: #5f6368;

    a {
        color: #5f6368;
    }
`

const Trust = ({ data }) => (
    <MainContainer>
        {data.trustComponents.map(({ number, text }) => (
            <div key={number}>
                <Number>{number}</Number>
                <Text dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        ))}
    </MainContainer>
)

export default Trust
