import React from 'react'
import styled from 'styled-components'
import Testimonial from './testimonials'
import { Button, RegisterButton } from '../../UI'

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    @media (max-width: 768px) {
        align-items: ${(props) => (props.bigButtonOnMobile ? 'flex-start' : null)};
    }
`

const Heading = styled.h1`
    margin-bottom: 1rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    letter-spacing: -0.025em;
    align-items: flex-start;
`
const ReviewButton = styled(Button)`
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: ${(props) => (props.bigButtonOnMobile ? null : '10px 38px')};
        text-align: center;
    }
`

const TestimonialsContainer = ({ data, bigButtonOnMobile, useRegisterButton }) => (
    <MainContainer bigButtonOnMobile={bigButtonOnMobile}>
        <Heading>{data.heading}</Heading>
        <Testimonial data={data} />
        {useRegisterButton ? (
            <RegisterButton url={data.url} lang={data.lang} tier={data.tier} campaign={data.campaign} big purple style={{ textAlign: 'center' }}>
                {data.textCTA} <span className="arrow">{'->'}</span>
            </RegisterButton>
        ) : (
            <ReviewButton href={data.url} target="_blank" bigButtonOnMobile={bigButtonOnMobile}>
                {data.textCTA}
                <span className="arrow">{'->'}</span>
            </ReviewButton>
        )}
    </MainContainer>
)

export default TestimonialsContainer
