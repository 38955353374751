import styled from 'styled-components'

export const Text = styled.p`
    font-size: 0.875rem;
    font-weight: 400;
    margin: 12px 0 0 0;
    overflow: hidden;
    @media (max-width: 768px) {
        margin: unset;
    }
`
export const Name = styled.p`
    font-size: 1rem;
    font-weight: 700;
    color: ${(props) => props.theme.color.tellowPurple};
    margin: 0;
`
export const Position = styled.p`
    font-size: 0.75rem;
    font-weight: 600;
    color: ${(props) => props.theme.color.tellowGray500};
    margin: 0;
`
