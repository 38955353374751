import React from 'react'
import styled from 'styled-components'
import StarRatings from 'react-star-ratings'
import { ComponentContainer, RelativeContainer } from '../../shared/landingPages/layoutComponents'
import Carousel from './carousel'
import 'swiper/css'
import { RegisterButton } from '../../UI'
import { Name, Position, Text } from './carouselStyling'

const Wrapper = styled.div`
    padding: 1rem 0;
    background: ${(props) => props.theme.color.tellowPurple};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        width: 90%;
        margin: 1rem;
        border-radius: 10px;
    }
`

const CarouselWrapper = styled.div`
    overflow: hidden;
    width: 100%;
`
const Title = styled.h1`
    margin: 3rem 0 0 0;
    font-size: 1.875rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    color: ${(props) => props.theme.color.tellowWhite};
    @media (max-width: 768px) {
        margin: 2rem 0;
    }
`
const SignupButton = styled(RegisterButton)`
    background-color: ${(props) => props.theme.color.tellowWhite};
    color: ${(props) => props.theme.color.tellowPurple};
    margin: 2rem;
`

const ComponentContainerStyle = styled(ComponentContainer)`
    margin-bottom: 0;
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 25px 5px;

    @media screen and (max-width: 768px) {
        padding: 15px 5px;
    }
`

const CardWrapper = styled.div`
    padding: 0 20px;
    @media screen and (max-width: 900px) {
        padding: 0 25px;
    }

    @media screen and (max-width: 768px) {
        padding: 0 10px;
    }

    @media screen and (max-width: 400px) {
        padding: 0 20px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 55px;
    position: relative;
    top: 10px;
    @media screen and (max-width: 1050px) {
        top: 0;
    }
`

const UserReview = ({ review, name, position }) => (
    <Card>
        <StarRatings starDimension="20px" starSpacing="1.5px" starRatedColor="rgb(255,204,104)" starEmptyColor="rgba(255,204,104,0.4)" rating={5} />
        <CardWrapper>
            <Text>{review}</Text>
            <Container>
                <Name>{name}</Name>
                <Position>{position}</Position>
            </Container>
        </CardWrapper>
    </Card>
)

const UserReviews = ({ data }) => (
    <ComponentContainerStyle>
        <Wrapper>
            <Title
                dangerouslySetInnerHTML={{
                    __html: data.title,
                }}
            />
            <CarouselWrapper>
                <RelativeContainer>
                    <Carousel bulletInactiveColor="transparent" bulletColor="#ffffff" bulletBorderColor="#ffffff" amount={data.testimonialCard.length}>
                        {data.testimonialCard.map(({ review, name, position }) => (
                            <UserReview key={review} review={review} name={name} position={position} />
                        ))}
                    </Carousel>
                </RelativeContainer>
            </CarouselWrapper>
            <SignupButton big>
                <span>Probeer Tellow gratis</span>
                <span className="arrow">{'->'}</span>
            </SignupButton>
        </Wrapper>
    </ComponentContainerStyle>
)

export default UserReviews
