import React from 'react'
import { useCountUp } from 'react-countup'
import styled from 'styled-components'
import { RegisterButton } from '../../UI'

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.color.tellowPurple};
`
const Title = styled.h1`
    font-size: 2.25rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: -0.025em;
    color: ${(props) => props.theme.color.tellowWhite};
    @media (max-width: 640px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
    }
    @media (min-width: 768px) {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    @media (min-width: 1024px) {
        font-size: 2.9rem;
        line-height: 1;
    }
`
const Text = styled.p`
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5;
    max-width: 48rem;
    opacity: 0.8;
    color: ${(props) => props.theme.color.tellowWhite};
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`
const Layout = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 80rem;
    padding: 2.5rem 1rem;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
        padding: 3rem 1rem;
        max-width: 64rem;
    }
`
const SignupButton = styled(RegisterButton)`
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: normal;
    word-break: normal;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.color.tellowPurple};
    background-color: ${(props) => props.theme.color.tellowWhite};
    padding: 18px 28px;
    @media (max-width: 768px) {
        padding: 10px 38px;
    }
`

const ReviewHeader = React.forwardRef(({ data }, ref) => {
    useCountUp({ ref: 'counter', start: 70500, end: 100000, duration: 1.5, separator: '.' })

    return (
        <Wrapper ref={ref}>
            <Layout>
                <Title>{data.title}</Title>
                <Text>
                    {data.text1} <span style={{ minWidth: '6ch', display: 'inline-block' }} id="counter" /> {data.text2}
                    <br />
                    {data.text3}
                </Text>
                <SignupButton>
                    {data.textCTA} <span className="arrow">{'->'}</span>
                </SignupButton>
            </Layout>
        </Wrapper>
    )
})

ReviewHeader.displayName = 'ReviewHeader'
export default ReviewHeader
