import React from 'react'
import styled from 'styled-components'

const Testimonial = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2.5rem;

    &.rowReverse {
        display: flex;
        flex-direction: row-reverse;
        .flex-item {
            width: 50%;
        }
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            .flex-item {
                width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 0rem;
        margin-bottom: 1rem;
    }
`

const Quote = styled.div`
    margin-bottom: -1rem;
    font-size: 5rem;
    line-height: 1;
    font-weight: 700;
    color: ${(props) => props.theme.color.tellowPurple};
    @media (max-width: 768px) {
        margin-bottom: -2rem;
    }
`
const Title = styled.h1`
    margin-bottom: 1rem;
    font-size: 1.625rem;
    line-height: 2.25rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 600;
    letter-spacing: -0.025em;
    align-items: flex-start;
    color: ${(props) => props.theme.color.tellowPurple};
    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`

const Text = styled.p`
    margin: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    align-items: flex-start;
    @media (max-width: 768px) {
        font-size: 0.875rem;
    }
`
const TextContainer = styled.div`
    border-left: solid 1px #dadada;
`

const Name = styled.h3`
    margin-bottom: 0.2rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    align-items: flex-start;
    color: black;
`
const Position = styled.p`
    font-size: 0.875rem;
    color: #9da7b3;
`
const UserImage = styled.img`
    @media (max-width: 768px) {
        margin-bottom: 0rem;
    }
`

const Testimonials = ({ data }) => (
    <div>
        {data.testimonials.map(({ title, text, name, position, img }, index) => (
            <Testimonial key={title} className={index % 2 === 0 ? null : 'rowReverse'}>
                <div className="flex-item">
                    <Quote>“</Quote>
                    {title && <Title>{title}</Title>}
                    <TextContainer>
                        <Text>{text}</Text>
                    </TextContainer>
                    <Name>{name}</Name>
                    <Position>{position}</Position>
                </div>
                <div className="flex-item">
                    <UserImage src={img} alt="Customer image" />
                </div>
            </Testimonial>
        ))}
    </div>
)

export default Testimonials
